body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.size img{ 
  width:50px;
  height:50px;
  object-fit: contain;
}
title text{

  font-size: 500%;
  background-color: aqua;

}

.mini-artwork img{ 
  width:50px;
  height:50px;
  object-fit: contain;
}

/*this style controls the image  appearance in the querysection page*/
.querySectionPicture img {
  height: 60px;
  width: 60px;
}

label[for="artwork"] {
  align-items: center;
  /* cursor: crosshair; */
}

button[type="submit"] {
  background-color: #FFB833 !important;
  font-color: #000;
}

input[name="username"]:focus {
  border-color:  #FFB833 !important;
}

input[name="password"]:focus {
  border-color:  #FFB833 !important;
}

/* CSS FOR MODAL */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.6); */
}

.pre-modal-main {
  position: fixed;
  top:50%;
  left:50%;
  width: 80%;
  transform: translate(-50%,-50%);
  background: #303030;
  padding: 35px;
  padding-bottom: 10px;
};

.modal-main {
  background: #424242 !important;
  width: 80%;
  height: auto;
  padding: 50px;
}

.modal-close-button {
  margin-left: 80% !important;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.statistics_image_reduction > img {
  max-height: 3rem !important;
}